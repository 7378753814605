// variables
@import 'variables-global';

/* You can add global styles to this file, and also import other style files */

html {
  background-size: cover;
  background: $main-background-color;
  background: $main-background;
  background-repeat: no-repeat;
  background-attachment: fixed !important;
}

body {
  --tui-font-text: 'Manrope', -apple-system, 'Roboto', 'Segoe UI', 'BlinkMacSystemFont', system-ui, 'Helvetica Neue',
    sans-serif;
  --tui-primary: #{$color-primary};
  --tui-text-01: #{$color-primary};
  --tui-primary-hover: #{rgba($color-primary, 0.85)};
  --tui-primary-active: #{$color-violet};
  --tui-primary-text: #{$color-white};
  --tui-secondary: #{rgba($color-accent, 0.15)};
  --tui-secondary-hover: #{rgba($color-accent, 0.3)};
  --tui-secondary-text: #{$color-secondary};
  --tui-radius-s: 0;
  --tui-radius-m: 4px;
  --tui-radius-l: 4px;
  --tui-height-l: 70px;
  --tui-link: #{$color-accent};
  --tui-link-hover: #{$color-secondary};

  [tuiWrapper][data-appearance='textfield'][data-state='readonly'][data-state='readonly'] {
    background-color: #f6f7f8;

    input {
      cursor: default;
    }
  }
}

.background {
  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  position: relative;
  z-index: 1;
  background: inherit;
  overflow: hidden;
}

.background:before {
  content: '';
  position: absolute;
  background: inherit;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  box-shadow: inset 0 0 2000px rgba(255, 255, 255, 0.5);
  filter: blur(10px);
  margin: -20px;
}

.m-auto {
  margin: auto;
}

.m-0 {
  margin: 0;
}

.row {
  display: flex;
}

.col {
  display: flex;
  flex-direction: column;
}

.row-wrap {
  @extend .row;
  flex-wrap: wrap;
}

.col-wrap {
  @extend .col;
  flex-wrap: wrap;
}

.justify-start {
  justify-content: start;
}

.justify-center {
  justify-content: center;
}

.justify-end {
  justify-content: end;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.align-start {
  align-items: start;
}

.align-center {
  align-items: center;
}

.align-end {
  align-items: end;
}

.cursor-pointer {
  cursor: pointer;
}
