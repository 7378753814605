/* colors */
$color-white: #fff;
$color-white-1: #ededed;
$color-white-0: #ffffff4a;
$color-white-6: #eff6fc;

$color-silver: #cecece;
$color-grey-0: #bbbdbb30;

$color-black-0: #000000db;

$color-red: #dd3030;
$color-red-1: #e65b65;
$color-red-2: #c35058;

$color-gold: #e3b719;
$color-yellow: #ffd747;

$color-green-light: #bae5d0;
$color-green: #219653;
$color-green-0: #7cc3618a;
$color-green-00: #79bb606b;
$color-green-000: #7adc551c;
$color-green-6: #90e0b9;

$color-blue-light-1: #f5f9ff;
$color-blue-light-2: #e5eff8;
$color-blue: #77b1ea;
$color-blue-dark: #0059a4;

$color-violet: #7f3b8a;
$color-violet-1: #565c94;
$color-violet-2: #7f849f;
$color-violet-0: #909eb86b;

$color-pink-1: #db8585;
$color-pink-2: #f5a3a3; // 1 file
$color-pink-3: #f29a9a; // 1 file
$color-pink-4: #f45454; // 1 file
$color-pink-5: #d17c7c; // 2 files
$color-pink-light-1: #f8dcdc; // 2 files
$color-pink-light-2: #f5dcdb; // 2 files
$color-pink-light-3: #d49e9e; // 2 files
